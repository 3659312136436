.dropzone {
    background: transparent;
    display: flex;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    margin-top: 20px;
    padding: 20px;
    cursor: pointer;
    height: 150px;
    padding: 10px 10px 10px 15px;
    outline: 1px dashed rgb(27, 27, 27, 0.2) ;
    width: 100%
  }

.subtitle-font-size{
  font-size: 10px;
}

  .landing-image {
    max-height: 300px;
    width: 100%;
  }

.whiten-text span {

  color: white;
    span.flip-countdown-title {
      color: white !important;
    }
}


.align-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.thank-you-wrapper {
  width: 100%;
  margin: 30px 0;
  padding-left: 20px;
  padding-right: 20px;
}

.hero-content {
  text-align: center;
  margin: 0 30px;
  padding: 25px 0 20px;
  > p {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 3s 4s, opacity 4s linear;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 4s linear;
}