/* HIDE RADIO */
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
  max-height: 100%;
  max-width: 100%;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f00;
}



label {
  border-style: solid;
  
}


#greybox {
  background-color: #f3f3f3 ;
  text-align: left;
  padding-top: 5px;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-left: 15px;
}